<template>
  <section>
    <b-card>
      <b-card-title>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="relief-primary"
              @click.stop="$router.push({ name: 'Controle Infinity (Gerenciar Ganhos / Adicionar)' })"
            >
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <TablePagination
        v-model="items"
        url="/user/controle_infinity/manage_earns/paginate"
        :per-page="10"
        :fields="fields"
        default-filter-by="value"
      >
        <template v-slot:value="data">
          {{ data.items.value | currency }}
        </template>

        <template v-slot:destination="data">
          {{ data.items.destination }}
        </template>

        <template v-slot:status="data">
          <b-button
            size="sm"
            :variant="getStatus(data.items.status).color"
          >
            {{ getStatus(data.items.status).message }}
          </b-button>
        </template>

        <template v-slot:request_date="data">
          {{ data.items.request_date | date }}
        </template>

        <template v-slot:platform="data">
          {{ data.items.platform }}
        </template>

        <template v-slot:actions="data">
          <b-button
            v-if="data.items.status === 1"
            class="mr-1"
            size="sm"
            variant="relief-success"
            :disabled="loading"
            @click.stop="setStatus(data.items, 3)"
          >
            <feather-icon
              v-if="!loading"
              icon="CheckIcon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            v-if="data.items.status === 1"
            class="mr-1"
            size="sm"
            variant="relief-danger"
            :disabled="loading"
            @click.stop="setStatus(data.items, 2)"
          >
            <feather-icon
              v-if="!loading"
              icon="XCircleIcon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            class="mr-1"
            :disabled="loading"
            size="sm"
            variant="relief-primary"
            @click.stop="open(data.items.id)"
          >
            <feather-icon
              v-if="!loading"
              icon="EditIcon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            :disabled="loading"
            size="sm"
            variant="relief-danger"
            @click.stop="dialogConfirmDelete(data.items)"
          >
            <feather-icon
              v-if="!loading"
              icon="Trash2Icon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>
        </template>
      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import { BSpinner, BCard, BButton } from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'
import checkSubscription from '../checkSubscription'

export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).format('DD/MM/YYYY HH:mm')
    }
  },
  mixins: [checkSubscription],
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'value',
        label: 'Valor',
        sortable: true
      },
      {
        key: 'destination',
        label: 'Destino',
        sortable: true
      },
      {
        key: 'status',
        label: 'Situação',
        sortable: true
      },
      {
        key: 'request_date',
        label: 'Data de Solicitação',
        sortable: true
      },
      {
        key: 'platform',
        label: 'Plataforma',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    getStatus (status) {
      const response = {
        message: '',
        color: ''
      }

      switch (status) {
      case 1:
        response.message = 'Pendente'
        response.color = 'relief-primary'
        break
      case 2:
        response.message = 'Reprovado'
        response.color = 'relief-danger'
        break
      case 3:
        response.message = 'Aprovado'
        response.color = 'relief-success'
        break
      default:
        response.message = 'Não definido'
        response.color = 'secondary'
        break
      }

      return response
    },
    async setStatus (item, status) {
      this.loading = true
      const url = `/user/controle_infinity/manage_earns/set_status/${item.id}/${status}`

      await this.$http.get(url)
        .then(() => {
          // get index and update status
          const index = this.items.findIndex(i => i.id === item.id)
          this.items[index].status = status
        }).finally(() => {
          this.loading = false
        })
    },
    open (id) {
      this.$router.push({ name: 'Controle Infinity (Gerenciar Ganhos / Editar)', params: { id } })
    },
    dialogConfirmDelete (item) {
      this.$swal({
        title: 'Você tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(result => {
        if (result.value) {
          this.deleteSelected(item.id)
        }
      })
    },
    async deleteSelected (id) {
      this.loading = true
      const url = `/user/controle_infinity/manage_earns/${id}`

      await this.$http.delete(url)
        .finally(() => {
          this.items = this.items.filter(i => i.id !== id)
          this.loading = false

          this.$swal({
            title: 'Apagado!',
            text: 'Apago com sucesso!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        })
    }
  }
}
</script>
