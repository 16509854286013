import currency from 'currency.js'

export default (val, min, max) => {
  const currencyVal = parseFloat(val)
  const currencySplit = String(currencyVal).split('.')
  const precision = currencySplit.length > 1 ? (currencySplit[1].length > min ? max : min) : min

  return currency(currencyVal, {
    separator: '.',
    decimal: ',',
    symbol: 'R$ ',
    precision: precision
  }).format()
}
